@import "./variable";

@font-face {
    font-family: $baseFont;
    src: url("../fonts/Gotham\ Book.otf") format("opentype");
}

html {
    font-size: $font-base-rem;
}

body {
    font-family: $baseFont;
    color: $primaryText;
    background-color: $background;
}

h1, h2, h3, h4, h5, h6, p {
    font-family: $baseFont;
}

div a {
    color: $primaryBrandColor;
    text-decoration: none; 
}

/* Button */

@media (min-width: 768px) {
    /* Adjust margin between buttons for desktop view */
    .popup-dark-btn {
      margin-right: 10px;
    }
    .popup-light-btn {
      margin-left: 10px;
    }
}

#approve-btn,
.approve-btn {
    background-color: $successBtn;
    border: none;
    color: $secondaryText;
    width: 100px;
}

.approve-light-btn {
    background-color: $successBtn;
    border: none;
    width: 300px;
}

.approve-popup-btn {
    background-color: $successBtn;
    border: none;
    color: $secondaryText;
    width: 100%;
}

.approve-popup-light-btn {
    background-color: $successBtn;
    border: none;
    width: 100%;
}

.btn-text {
    font-size: 20px;
    line-height: 42px;
    font-family: $baseFont;
    letter-spacing: 0;
    color: $secondaryText;
}

.btn:hover {
    background-color: $cancelBtn;
    color: $secondaryText;
}

.cancel-btn {
    background-color: $cancelBtn;
    border: none;
    color: $secondaryText;
    width: 300px;
}

.cancel-popup-btn {
    background-color: $cancelBtn;
    border: none;
    color: $secondaryText;
    width: 100%;
}

.close-btn {
    text-decoration: none;
    color: $cancelBtn;
    padding-right: 0;
    margin-bottom: 10px;
}

.dark-btn {
    background-color: $defaultBtn;
    border: none;
    width: 250px;
}

#deny-btn,
.deny-btn {
    background-color: $cancelBtn;
    border: none;
    color: $secondaryText;
    width: 100px;
}

.edit-info-btn {
    color: $successBtn;
    text-decoration: none;
}

.facility-btn {
    background-color: $defaultBtn;
    color: $secondaryText;
    width: 200px;
    height: 200px;
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .facility-btn {
      width: 150px;
      height: 150px;
      font-size: 1.2rem;
    }
    .flex-wrap {
      flex-wrap: wrap;
    }
    .flex-wrap > * {
      flex-basis: 100%;
    }
    .flex-wrap {
      flex-direction: column;
    }
}

.get-code-btn {
    background-color: $successBtn;
    color: $secondaryText;
}

.load-csv-btn {
    background-color: $defaultBtn;
    color: $secondaryText;
}

.download-csv-btn {
    background-color: $successBtn;
    color: $secondaryText;
}

.light-btn {
    background-color: $successBtn;
    border: none;
    width: 250px;
}

.minus-btn {
    background-color: $cancelBtn;
    color: $secondaryText;
}

.no-hover:hover {
    background-color: transparent;
    color: inherit;
}

.plus-btn {
    background-color: $defaultBtn;
    color: $secondaryText;
}

.send-code-btn {
    background-color: $defaultBtn;
    color: $secondaryText;
}

.update-add-user-btn {
    background-color: $successBtn;
}

.view-btn {
    border: none;
    color: $primaryText;
    text-decoration: underline;
    background-color: transparent;
    cursor: pointer;
}


/* Notification */

.error, 
.success {
    background-color: $notificationError;
    padding: 10px;
    border-radius: 3px;
    width: auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $primaryText;
}

.success {
    background-color: $notificationSuccess;
}

/* Table Styling */

table {
    border-collapse: collapse;
    width: 85%;
    margin-bottom: 1em;
    margin: 0 auto;
}
thead th {
    border-bottom: 1px solid lightgrey;
    text-align: center;
    padding: 0.5em;
    color: $primaryText;
}

tbody td {
    text-align: center;
    padding: 0.5em;
    border-bottom: 1px solid lightgrey;
    color: $primaryText
}

tbody tr:last-child td {
    border-bottom: none;
}


/* Popup Styling */

.approve-cancel-popup-content,
.user-delete-popup-content {
    background-color: $background;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px $popupBackground;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
}

.booking-popup,
.booking-cancel-popup,
.code-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $popupBackground;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.booking-popup-content,
.booking-cancel-popup-content,
.code-popup-content {
    background-color: $background;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px $popupBackground;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
}

.deny-popup {
    background-color: $background;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px $popupBackground;
    overflow: auto;
    width: 400px;
}

@media only screen and (max-width: 576px) {
	.booking-popup-content,
	.booking-cancel-popup-content {
		max-width: 80%;
		max-height: 100%;
	}

	h2 {
		font-size: 1.6rem;
		margin-top: 1rem;
		margin-bottom: 2rem;
	}

	.text-center p {
		font-size: 0.9rem;
	}

	.popup-dark-btn,
	.popup-light-btn {
		font-size: 0.8rem;
		padding: 0.5rem;
		margin: 0.5rem;
	}
}


/* Calendar Styling */

.confirmed {
    background-color: $cancelBtn;
}

.pending {
    background-color: $primaryText;
}

.turnover {
    background-color: #A9A9A9;
}

.conferenceOne {
    background-color: #D11F43;
}

.conferenceTwo {
    background-color: #03AEDA;
}

.conferenceOneTwo {
    background-color: #264653;
}

.levelTwo {
    background-color: #FFC529;
}

.levelFour {
    background-color: #F47736;
}

.conferenceFiveSix {
    background-color: #0DB158;
}

.conferenceOnePending {
    background-color: #E2A6B6;
}

.conferenceTwoPending {
    background-color: #AFD4DD;
}

.conferenceOneTwoPending {
    background-color: #578191;
}

.levelTwoPending {
    background-color: #F7E1B0;
}

.levelFourPending {
    background-color: #F7C9B6;
}

.conferenceFiveSixPending {
    background-color: #BEE1CA;
}

.legend {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.fc-daygrid-day-number {
    text-decoration: none;
}

.fc-col-header-cell-cushion {
    text-decoration: none;
}

.fc-col-header-cell {
    background-color: $defaultBtn;
}

.fc-toolbar-title {
    font-family: $baseFont;
}

@media (max-width: 767px) {
    .fc-daygrid-more-link.fc-more-link {
        font-size: 10px
      }
}

/* Table Styling */

.my-pagination .active .page-link {
    /* Modify the styles for the active page link */
    background-color: $successBtn;
    border-color: #A9A9A9;

}

.my-pagination .page-item:not(.active) .page-link {
    /* Modify the styles for non-active page links */
    background-color: $background;
    color: $primaryText;
    border-color: #A9A9A9;
}
  

/* Form Styling */

@media (max-width: 767px) {
    .layout-img {
        width: 100%;
        max-width: 350px;
        height: 40px;
    }
}

/* Misc Styling */

@media (max-width: 767px) {
    .d-flex.justify-content-end.align-items-center.my-3.me-3 div,
    .btn.logOut {
      font-size: 12px; /* Adjust the font size as needed */
    }
    #footer-text {
        font-size: 10px;
    }
    #otp-text {
    font-size: 12px;
    }
    #logo-booking {
        display: none;
    }
}

.cancel-link {
    color: $successBtn;
}

.danger-text {
    color: $cancelBtn;
}

.fa-pencil {
    color: $primaryText;
}

.fa-times {
    color: $cancelBtn;
}

.fa-check {
    color: $successBtn;
}

.fa-home {
    color: $primaryText;
    font-size: 24px;
}

.logOut {
    color: $cancelBtn;
    text-decoration: none;
}

/* Add appropriate color styles for each status */
.status-up {
    color: green;
}

.status-down {
    color: red;
}

.status-not-monitored
.status-standby {
    color: gray;
}

.totp-text {
    color: $successBtn;
}