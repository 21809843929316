$baseFont: "Gotham Book";

// Type Scales (Base 1rem = 16px, Scale: 1.25)
$font-base-rem: 16px;

// Color
$background: var(--background);
$popupBackground: #00000080;
$primaryText: var(--primaryText);
$secondaryText: var(--secondaryText);
$primaryBrandColor: var(--primaryBrandColor);
$darkAccent: var(--darkAccent);
$lightAccent: var(--lightAccent);
$defaultBtn: var(--defaultBtn);
$successBtn: var(--successBtn);
$cancelBtn: var(--cancelBtn);
$notificationSuccess: #ccffcc;
$notificationError: #ffcccc;